import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []

  get message() { return this.data.get("message") }

  connect() {
    this.onBeforeUnloadHandler = () => this.message

    this.hasChanges = false
    this.onBeforeUnloadHandlerAttached = false
  }

  disconnect() {
    this.detachUnloadListener()
  }

  trackChange() {
    this.hasChanges = true
    this.attachUnloadListener()
  }

  handleSubmit(e) {
    this.detachUnloadListener()
  }

  handleTurbolinksNavigation(e) {
    if (this.hasChanges) {
      if (!confirm(this.message)) {
        e.preventDefault()
      }
    }
  }

  // ===================
  // = Unload Handling =
  // ===================

  attachUnloadListener() {
    window.onbeforeunload = this.onBeforeUnloadHandler
    this.onBeforeUnloadHandlerAttached = true
  }

  detachUnloadListener() {
    if (!this.onBeforeUnloadHandlerAttached) { return }

    window.onbeforeunload = undefined
    this.onBeforeUnloadHandlerAttached = false
  }
}
