import { Controller } from '@hotwired/stimulus'

function splitClassNames(string) {
  return (string || "").split(/\s/).filter((value) => value)
}

export default class extends Controller {
  static targets = ["sidebar", "button"]

  get sidebarActiveClasses() { return this.data.get("active") }
  get sidebarInactiveClasses() { return this.data.get("inactive") }

  get buttonActiveClasses() { return this.data.get("button-active") }
  get buttonInactiveClasses() { return this.data.get("button-inactive") }

  get active() { return this.data.get("active") == "true" }
  set active(value) { this.data.set("active", value ? "true" : "false") }

  connect() {
    this.restoreState()
  }

  toggle(e) {
    e.preventDefault()

    if (this.active) {
      this.disable()
    } else {
      this.enable()
    }
  }

  enable() {
    this.updateClasses(this.sidebarTarget, this.sidebarActiveClasses, this.sidebarInactiveClasses)
    this.updateClasses(this.buttonTarget,  this.buttonActiveClasses,  this.buttonInactiveClasses)
    this.active = true
  }

  disable() {
    this.updateClasses(this.sidebarTarget,  this.sidebarInactiveClasses,  this.sidebarActiveClasses)
    this.updateClasses(this.buttonTarget,   this.buttonInactiveClasses,   this.buttonActiveClasses)

    this.active = false
  }

  restoreState() {
    if (this.active) {
      this.enable()
    } else {
      this.disable()
    }
  }

  updateClasses(target, addClasses, removeClasses) {
    const { classList } = target

    const classesToAdd    = splitClassNames(addClasses)
    const classesToRemove = splitClassNames(removeClasses)

    if (classesToAdd.length > 0){
      classList.add(...classesToAdd)
    }

    if (classesToRemove.length > 0){
      classList.remove(...classesToRemove)
    }
  }

}
