import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ["content", "button"]

  get placement() { return this.data.get("placement") || "bottom-end"}

  get isVisible() { return this.data.get("isVisible") == "true" }
  set isVisible(value) { this.data.set("isVisible", (value ? "true" : "false")) }

  get popperReference() { return this.hasButtonTarget ? this.buttonTarget : this.element }

  connect() {
    this._restoreViewState()
  }

  toggle(e) {
    if (e) { e.preventDefault(); e.stopPropagation() }

    if (!this.isVisible) {
      this._show()
    } else {
      this._hide()
    }
  }

  respondToKeypress(e) {
    if (!this.isVisible) { return }

    if (e.key == "Escape") {
      this._hide()
      e.preventDefault()
    }
  }


  hideIfClickedOutside(e) {
    if (!this.isVisible) { return }

    const { target } = e

    if (!this._elementIsPartOfDropdown(target)) {
      this._hide()
    }
  }

  // =================
  // = View Handling =
  // =================

  _restoreViewState() {
    if (this.isVisible) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show() {
    this.isVisible = true
    this.contentTarget.classList.remove("hidden")

    if (!this.popper) {
      this.popper = createPopper(this.popperReference, this.contentTarget, { placement: this.placement })
    }
  }

  _hide() {
    this.isVisible = false
    this.contentTarget.classList.add("hidden")

    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }

  // ===========
  // = Helpers =
  // ===========

  _elementIsPartOfDropdown(element) {
    let currentElement = element;

    while (currentElement) {
      if (currentElement == this.contentTarget) {
        return true
      }

      currentElement = currentElement.parentElement
    }

    return false
  }
}

