import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  get targetGroup() { return this.data.get("target") }
  get targets() { return document.querySelectorAll(`*[data-toggle-group=${this.targetGroup}]`) }

  get active() { return this.data.get("active") == "true" }
  set active(value) { this.data.set("active", value ? "true" : "false") }

  connect() {
    this._refreshView()
  }

  toggle(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.active = !this.active
    this._refreshView()
  }

  enable() {
    if (this.active) { return }

    this.active = true
    this._refreshView()
  }

  disable() {
    if (!this.active) { return }

    this.active = false
    this._refreshView()
  }

  _refreshView() {
    this.targets.forEach(this._updateTarget.bind(this))
  }

  _updateTarget(target) {
    const activeClasses = target.dataset.toggleActiveClass
    const inactiveClasses = target.dataset.toggleInactiveClass

    this._toggleClasses(target, activeClasses, this.active)
    this._toggleClasses(target, inactiveClasses, !this.active)
  }

  _toggleClasses(target, classString, presence) {
    const { classList } = target
    const classes = (classString || "").split(/\s/).filter((className) => className)

    classes.forEach((className) => classList.toggle(className, presence))
  }

}
