import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

function trigger(element, name, detail) {
  const data = {
    "bubbles": true,
    "detail": detail
  }

  const event = new CustomEvent(name, data)

  element.dispatchEvent(event)
}

export default class SelectizeController extends Controller {
  static targets = ["input"]

  get valueField()  { return this.data.get("value") || "title" }
  get labelField()  { return this.data.get("label") || "title" }
  get searchField() { return this.data.get("search") || this.labelField }
  get sortField()   { return this.data.get("sort") || this.labelField }

  connect() {
    const selectizeOptions = this._buildSelectizeOptions()
    const triggerChangeEvent = () => trigger(this.element, "changed")

    $(this.inputTarget).selectize(selectizeOptions)
      .on("change", triggerChangeEvent)
      .on("item_add", triggerChangeEvent)
      .on("item_remove", triggerChangeEvent)
  }

  disconnect() {
    $(this.inputTarget).selectize("destroy").show()
    $(this.element).find(".selectize-control").remove()
  }

  _buildSelectizeOptions() {
    const loadOptions = this.loadOptions.bind(this)

    return {
      create: true,
      persisted: false,
      plugins: [ 'remove_button'],
      valueField: this.valueField,
      labelField: this.labelField,
			searchField: this.searchField,
			sortField: this.sortField,
      options: [],
      load: function(query, callback) {
        this.clearOptions()

        loadOptions(query, callback)
      }
    }
  }

  loadOptions(query, callback) {
    const url = this.data.get("url").replace("QUERY", window.encodeURIComponent(query))

    fetch(url)
      .then((res) => res.json())
      // .then((json) => { console.log(json); return json })
      .then((json) => callback(json))
      .catch((e) => {
        console.error("Failed to load selectize content", e)

        callback([])
      })
  }
}