import $ from "jquery"

const Selectors = {
  HOME: "*[data-behavior=home-link]",
  NEW: "*[data-behavior=new-link]",
  EDIT: "*[data-behavior=edit-link]",
  CANCEL: "*[data-behavior=cancel-link]",
}

const mappings = {
  "n": { selector: Selectors.NEW, target: "document"},
  "e": { selector: Selectors.EDIT, target: "document"},
  "h": { selector: Selectors.HOME, target: "document"},
  "Escape": { selector: Selectors.CANCEL, target: "global"}
}

$(document).on("keydown", (e) => {

  Object.keys(mappings).forEach((k) => {
    const mapping = mappings[k]
    const validTarget = (mapping.target === "document" && e.target === document.body) || (mapping.target === "global");

    if (validTarget && e.key == k) {
      e.preventDefault()

      const $link = $(mapping.selector)

      if ($link.length > 0){
        $link[0].click()
      }
    }
  })

})

$(document).on("keydown", "*[data-behavior=meta-submit]", (e) => {

  if (e.metaKey && e.which == 13) {
    e.preventDefault()
    const $this = $(e.target)
    const $forms = $this.closest("form")

    if ($forms.length > 0) {
      // Hint: Simply calling submit() on the form does not create a native Event.
      //       Thus, we need to manually create a submit event, pass it through the DOM,
      //       and then check whether the event was cancelled via preventDefault()

      const event = new CustomEvent("submit", {bubbles: true, cancelable: true})
      const form = $forms[0]

      if (form.dispatchEvent(event)) {
        form.submit()
      }
    }
  }

})