import { Controller } from '@hotwired/stimulus'

const defaultOpacityClass = "opacity-100"

export default class extends Controller {
  static targets = ["closeButton"]

  get closed() { return this.data.get("closed") == "true" }
  set closed(value) { return this.data.set("closed", value ? "true" : "false") }

  connect() {
    this.closeButtonTarget.classList.remove("hidden")
    this.element.classList.add(defaultOpacityClass, "transition-all")
  }

  close(e) {
    e.preventDefault()

    this.closed = true
    this.element.classList.add("transform", "translate-x-64", "opacity-0")
    this.element.classList.remove(defaultOpacityClass)
  }

  removeAfterClose() {
    if (this.closed){
      this.element.remove()
    }
  }
}
