
// Entry point for the build script in your package.json
require("@rails/ujs").start()
require("@rails/activestorage").start()

import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"

import "./utils/keyboard"

import "./vendor";
import "./controllers"
